// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-collection-js": () => import("./../../../src/pages/collection.js" /* webpackChunkName: "component---src-pages-collection-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-dragonpass-js": () => import("./../../../src/pages/dragonpass.js" /* webpackChunkName: "component---src-pages-dragonpass-js" */),
  "component---src-pages-dwarves-js": () => import("./../../../src/pages/dwarves.js" /* webpackChunkName: "component---src-pages-dwarves-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lore-js": () => import("./../../../src/pages/lore.js" /* webpackChunkName: "component---src-pages-lore-js" */),
  "component---src-pages-mynfts-js": () => import("./../../../src/pages/mynfts.js" /* webpackChunkName: "component---src-pages-mynfts-js" */),
  "component---src-pages-slipstream-js": () => import("./../../../src/pages/slipstream.js" /* webpackChunkName: "component---src-pages-slipstream-js" */),
  "component---src-pages-stake-js": () => import("./../../../src/pages/stake.js" /* webpackChunkName: "component---src-pages-stake-js" */)
}

